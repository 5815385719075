import DataTableAction from '@/components/DataTable/DataTableAction'
import DataTableActionField from '@/components/DataTable/DataTableActionField'
import DynamicRate from '@/models/DynamicRate'
import router from '@/Routes'
import SystemtModule from '@/store/SystemModule'
import { getModule } from 'vuex-module-decorators'

export default [
  {
    key: 'id',
    label: 'Id',
    sortable: false,
    class: 'text-center align-middle',
    show: false,
  },
  {
    key: 'name',
    label: 'Name',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
  },
  {
    key: 'type',
    label: 'Type',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
  },
  {
    key: 'rate',
    label: 'Rate',
    sortable: true,
    class: 'text-center  align-middle',
    show: true,
    type: 'currency',
  },
  {
    key: 'allow_overwrite',
    label: 'Allow overwrite',
    sortable: true,
    class: 'text-center align-middle',
    show: true,
    type: 'badge',
    formatter: (allow_overwrite: any) => (allow_overwrite ? 'Yes' : 'No'),
    color: (dynamic_rate: DynamicRate) => (dynamic_rate.allow_overwrite ? 'primary' : 'danger'),
  },
  {
    key: 'updated_at',
    label: 'Updated At',
    sortable: true,
    class: 'text-center align-middle',
    show: true,
    type: 'date',
  },
  {
    key: 'created_at',
    label: 'Created At',
    sortable: true,
    class: 'text-center align-middle',
    show: true,
    type: 'date',
  },
  {
    key: 'status',
    label: 'Status',
    sortable: true,
    class: 'text-center align-middle',
    show: true,
    type: 'badge',
    color: (dynamic_rate: DynamicRate) => (dynamic_rate.status === 'active' ? 'primary' : 'danger'),
  },

  new DataTableActionField([
    new DataTableAction(
      'Edit',
      'edit',
      'pencil',
      () => {
        const { user } = getModule(SystemtModule)// Check if user has access
        return (user && user.can('reconciliation', 'edit')) as boolean
      },
      (item: DynamicRate) => {
        router.push({
          name: 'dynamic_rate_edit',
          params: { id: item.id },
        })
      },
    ),
  ]),
]
