// @ts-ignore
export default {
  custom_fields: [
    {
      name: 'status:inactive',
      value: 'status:inactive',
      description: 'Includes only records with inactive as status',
    },
    {
      name: 'status:active',
      value: 'status:active',
      description: 'Includes only records with active as status',
    },
  ],
}
