
import { Component, Ref } from 'vue-property-decorator'
import Widget from '@/components/Widget/Widget.vue'
import MediaPackage from '@/models/MediaPackage'
import ViewModel from '@/models/ViewModel'
import IconAction from '@/components/IconAction/IconAction.vue'
import SearchInput from '@/components/SearchInput/SearchInput.vue'
import DataTable from '@/components/DataTable/index.vue'
import { getModule } from 'vuex-module-decorators'
import SystemtModule from '@/store/SystemModule'
import DynamicRate from '@/models/DynamicRate'
import CheckboxInput from '@/components/CheckboxInput/index.vue'
import fields from './dynamic-home-table-fields'
import query_settings from './dynamic-search-query'

@Component({
  components: {
    Widget,
    IconAction,
    SearchInput,
    DataTable,
    CheckboxInput,
  },
})
export default class DynamicRateHome extends ViewModel {
  @Ref() readonly dataTable!: HTMLFormElement

  public media_package: MediaPackage = new MediaPackage()

  public records: number = 0

  public page: number = 1

  public loading: boolean = false

  public ready = false

  public query: string[] = []

  public selected: string[] = []

  public fieldFilters: any = {}

  public fields: Array<any> = []

  public get show_fields() {
    return this.fields.filter((f: any) => f.show)
  }

  public get query_options() {
    return query_settings
  }

  public rows(context: any) {
    this.loading = true
    const field_filters = Object.keys(this.fieldFilters)
      .filter((key: string) => this.fieldFilters[key] !== '')
      .map((key: string) => `${key}:${this.fieldFilters[key].toLowerCase()}`)
    this.syncFilters()

    return DynamicRate.paginate({
      page_size: 25,
      page: this.page,
      order_by: 'created_at',
      order: 'desc',
      query: [...context.filter, ...field_filters],
    }).then(result => {
      this.records = result.records
      this.loading = false
      return result.data
    })
  }

  public refresh() {
    this.dataTable.refresh()
  }

  public resetFilters() {
    this.fieldFilters = {}
    this.query = []
    this.clearFilters()
    this.refresh()
  }

  public mounted() {
    this.fields = fields
    this.loadFilters()
  }

  public syncFilters() {
    const system = getModule(SystemtModule)
    system.updateState({
      name: 'filters',
      type: 'dynamic_rates',
      data: { query: this.query, fieldFilters: this.fieldFilters },
    })
  }

  public loadFilters() {
    const system = getModule(SystemtModule)
    system.getFilter('dynamic_rates').then((filter: any) => {
      if (filter) {
        this.query = filter.query
        this.fieldFilters = filter.fieldFilters
      }
      this.ready = true
    })
  }

  public clearFilters() {
    const system = getModule(SystemtModule)
    system.updateState({
      name: 'filters',
      type: 'dynamic_rates',
      data: null,
    })
  }

  public createDynamicRate() {
    this.$router.push({ name: 'dynamic_rate_edit' })
  }
}
